import * as Yup from 'yup';

import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import React, { useState } from 'react';

import CustomSelectInput from './custom-select-input';
import { IoMdInformationCircleOutline } from "react-icons/io";
import Modal from 'react-modal';
import axios from 'axios';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const FormSection = () => {
  const { t } = useTranslation();

  const [isSubmitModal, setIsSubmitModal] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState('');

  const [selectedProgramPaths, setSelectedProgramPaths] = useState([])
  const programPaths = [
    t("register_form.innovate_it"),
    t("register_form.med_biz"),
    t("register_form.green"),
  ]

  const [extraTeammatesNumber, setExtraTeammatesNumber] = useState(0)

  const howYouKnowProgram = [
    {
      label: "Facebook",
      value: "facebook"
    },
    {
      label: "Instagram",
      value: "instagram"
    },
    {
      label: "Linkedin",
      value: "linkedin"
    },
    {
      label: t("register_form.website"),
      value: "website"
    },
    {
      label: t("register_form.previous_editions"),
      value: "previous_editions"
    },
    {
      label: t("register_form.from_friend"),
      value: "friend"
    },
    {
      label: t("register_form.other"),
      value: "other"
    },
  ];

  const initialFormValues = {
    name: "",
    dateOfBorn: "",
    email: "",
    phone: "",
    studentStatus: false,
    programPaths: [],
    applicationType: "",
    teammateName1: "",
    teammateEmail1: "",
    teammatePhone1: "",
    teammateName2: "",
    teammateEmail2: "",
    teammatePhone2: "",
    teammateName3: "",
    teammateEmail3: "",
    teammatePhone3: "",
    teammateName4: "",
    teammateEmail4: "",
    teammatePhone4: "",
    howYouKnowProgram: "",
    policyAccepted: false
  }

  const teammateNameValidation = (index) => {
    return Yup.string().when("applicationType", {
      is: (value) => value === t("register_form.team_application") && extraTeammatesNumber >= index,
      then: Yup.string().required(t("register_form.input_required")),
    })
  }

  const teammateEmailValidation = (index) => {
    return Yup.string().when("applicationType", {
      is: (value) => value === t("register_form.team_application") && extraTeammatesNumber >= index,
      then: Yup.string()
        .email(t("register_form.input_is_not_email"))
        .required(t("register_form.input_required")),
    })
  }

  const teammatePhoneValidation = (index) => {
    return Yup.string().when("applicationType", {
      is: (value) => value === t("register_form.team_application") && extraTeammatesNumber >= index,
      then: Yup.string()
        .min(9, t("register_form.input_is_not_phone"))
        .required(t("register_form.input_required")),
    })
  }

  // MedBiz starts 02.24.2024
  // 02.24.2006 - ppl need to be at least 18 years
  // 02.24.1994 - and max 30 years

  //TODO: zrobić to normalnie, a nie tak jak teraz, czemu każdy numer i email jest walidowany oddzielnie?
  const formValidationScheme = Yup.object().shape({
    name: Yup.string().required(t("register_form.input_required")),
    dateOfBorn: Yup.date()
      .max(new Date(2006, 1, 23), t("register_form.student_under_30"))
      .min(new Date(1994, 1, 24), t("register_form.student_under_30"))
      .required(t("register_form.input_required")),
    email: Yup.string()
      .email(t("register_form.input_is_not_email"))
      .required(t("register_form.input_required")),
    phone: Yup.string()
      .min(9, t("register_form.input_is_not_phone"))
      .required(t("register_form.input_required")),
    studentStatus: Yup.boolean().oneOf([true], t("register_form.student_under_30")),
    programPaths: Yup.array()
      .min(1, t("register_form.program_path_info")).of(Yup.string().required())
      .required(t("register_form.input_required")),
    applicationType: Yup.string().required(t("register_form.select_one_application")),
    teammateName1: teammateNameValidation(0),
    teammateEmail1: teammateEmailValidation(0),
    teammatePhone1: teammatePhoneValidation(0),
    teammateName2: teammateNameValidation(1),
    teammateEmail2: teammateEmailValidation(1),
    teammatePhone2: teammatePhoneValidation(1),
    teammateName3: teammateNameValidation(2),
    teammateEmail3: teammateEmailValidation(2),
    teammatePhone3: teammatePhoneValidation(2),
    teammateName4: teammateNameValidation(3),
    teammateEmail4: teammateEmailValidation(3),
    teammatePhone4: teammatePhoneValidation(3),
    howYouKnowProgram: Yup.string().required(t("register_form.input_required")),
    policyAccepted: Yup.boolean().oneOf([true], t("register_form.input_required"))
  });

  const onFormSubmit = async (values, { setErrors }) => {
    setSubmitSuccess('')
    setIsSubmitModal(true)
    axios.post('https://www.innovationshub.pl/api/register-form', values)
      .then(function (response) {
        setSubmitSuccess(response.data.status)
      })
      .catch(function (error) {
        setSubmitSuccess('error')
      });
  }

  const addExtraTeammate = () => {
    if (extraTeammatesNumber === 3) {
      return
    }
    setExtraTeammatesNumber(extraTeammatesNumber + 1)
  }

  const removeExtraTeammate = () => {
    if (extraTeammatesNumber === 0) {
      return
    }
    setExtraTeammatesNumber(extraTeammatesNumber - 1)
  }

  const boxStyles = {
    overlay: {
      zIndex: 103,
      backgroundColor: 'rgba(33, 33, 33, 0.40)'
    },
    content: {
      margin: 'auto',
      inset: '0',
      width: '80%',
      maxWidth: '580px',
      maxHeight: '595px',
      borderRadius: '10px'
    }
  };

  return (
    <section className="">
      <div className="container registration-form-main-ctn">
        <div className="registration-form-ctn">

          <Formik
            initialValues={initialFormValues}
            validationSchema={formValidationScheme}
            onSubmit={onFormSubmit}
          >
            {({ values, errors, touched }) => (
              <Form>
                <label className="registration-form-item_wrapper">
                  <span>{t("register_form.name")}</span>
                  <Field
                    id="name"
                    className={
                      errors?.name && touched?.name ? "error" : ""
                    }
                    name="name"
                    type="text"
                    placeholder={t("register_form.name_placeholder")}
                  />
                  <ErrorMessage name="name">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </label>

                <label className="registration-form-item_wrapper">
                  <span>{t("register_form.date")}</span>
                  <Field
                    id="dateOfBorn"
                    className={
                      errors?.dateOfBorn && touched?.dateOfBorn ? "error" : ""
                    }
                    name="dateOfBorn"
                    type="date"
                    placeholder={t("register_form.date_placeholder")}
                  />
                  <ErrorMessage name="dateOfBorn">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                  <div className="registration-from-data-picker-info">
                    <IoMdInformationCircleOutline />
                    <div>Jeżeli masz problem z wyborem roku, kliknij w rok, aby go prosto zmienić</div>
                  </div>
                </label>

                <label className="registration-form-item_wrapper">
                  <span>{t("register_form.email")}</span>
                  <Field
                    id="email"
                    className={
                      errors?.email && touched?.email ? "error" : ""
                    }
                    name="email"
                    type="email"
                    placeholder={t("register_form.email_placeholder")}
                  />
                  <ErrorMessage name="email">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </label>

                <label className="registration-form-item_wrapper">
                  <span>{t("register_form.phone")}</span>
                  <Field
                    id="phone"
                    className={
                      errors?.phone && touched?.phone ? "error" : ""
                    }
                    name="phone"
                    type="tel"
                    placeholder={t("register_form.phone_placeholder")}
                  />
                  <ErrorMessage name="phone">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </label>

                <div className="registration-form-item_wrapper">
                  <span style={{ marginTop: '8px' }}>
                    {t("register_form.do_you_have_student_status")}
                  </span>
                  <div className="checkbox-and-radio-input-group">
                    <label>
                      <Field
                        id="studentStatus"
                        name="studentStatus"
                        type="checkbox"
                      />
                      <div className="checkmark"></div>
                      <span className="policy-span">
                        {t("register_form.yes_i_have")}
                      </span>
                    </label>
                    <ErrorMessage name="studentStatus">
                      {(msg) => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="registration-form-item_wrapper">
                  <span style={{ marginTop: '8px' }}>
                    {t("register_form.program_path")}
                    <br />
                    <span>{t("register_form.program_path_info")}</span>
                  </span>

                  <FieldArray
                    id="programPaths"
                    name="programPaths"
                    render={(arrayHelpers) => (
                      <div className="checkbox-and-radio-input-group">
                        {programPaths.map((path, index) => (
                          <label key={index}>
                            <input
                              name={`assets.${index}`}
                              type="checkbox"
                              value={path}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  arrayHelpers.push(path);
                                  selectedProgramPaths.push(path)
                                } else {
                                  const pathIndex = selectedProgramPaths.indexOf(path)
                                  selectedProgramPaths.splice(pathIndex, 1)
                                  arrayHelpers.remove(pathIndex);
                                }
                              }}
                            />
                            <div className="checkmark"></div>
                            <span>{path}</span>
                          </label>
                        ))}
                      </div>
                    )}
                  />
                  <ErrorMessage name="programPaths">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="registration-form-item_wrapper">
                  <span style={{ marginTop: '8px' }}>
                    {t("register_form.application_type")}
                  </span>

                  <div className="checkbox-and-radio-input-group">
                    <label>
                      <Field
                        type="radio"
                        name="applicationType"
                        value={t("register_form.team_application")}
                      />
                      <div className="checkmark checkmark-radio"></div>
                      <span>{t("register_form.team_application")}</span>
                    </label>

                    <label>
                      <Field
                        type="radio"
                        name="applicationType"
                        value={t("register_form.single_application")}
                      />
                      <div className="checkmark checkmark-radio"></div>
                      <span>{t("register_form.single_application")}</span>
                    </label>
                  </div>

                  <ErrorMessage name="applicationType">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>

                {
                  values.applicationType === t("register_form.team_application") && (
                    <div className="extra-teammates_wrapper">
                      <div className="text-ctn">
                        <div className="title">{t("register_form.add_teammates")}</div>
                        <p className="title-info">
                          {t("register_form.add_teammates_info_1")} {' '}
                          <b>{t("register_form.add_teammates_info_2")}</b> {' '}
                          {t("register_form.add_teammates_info_3")}
                        </p>
                        <div className="warning-ctn">
                          <WarningIcon />
                          <span>{t("register_form.add_teammates_max4_warning")}</span>
                        </div>
                      </div>
                      <ExtraTeammate
                        index={0}
                        errors={errors}
                        touched={touched}
                      />

                      {
                        [...Array(extraTeammatesNumber)].map((e, i) => (
                          <ExtraTeammate
                            key={i}
                            index={i + 1}
                            errors={errors}
                            touched={touched}
                            canRemove={i === extraTeammatesNumber - 1}
                            remove={removeExtraTeammate}
                          />

                        ))
                      }

                      <div className="add-teammate-ctn">
                        <a
                          className={`contact-form__form__submit ${extraTeammatesNumber === 3 ? 'inactive' : ''}`}
                          onClick={() => addExtraTeammate()}
                        >
                          {t("register_form.add_teammate")}
                        </a>

                        {
                          extraTeammatesNumber === 3 && (
                            <div className="warning-ctn">
                              <WarningIcon />
                              <span>{t("register_form.added_max_teammates")}</span>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  )
                }

                <div className="registration-form-item_wrapper">
                  <span style={{ marginTop: '8px' }}>
                    {t("register_form.how_you_know_about_program")}
                  </span>

                  <Field
                    className="custom-select"
                    name="howYouKnowProgram"
                    options={howYouKnowProgram}
                    component={CustomSelectInput}
                    placeholder={t("register_form.select_one_option")}
                    isMulti={false}
                  />

                  <ErrorMessage name="howYouKnowProgram">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>

                <div className="registration-form-item_wrapper">
                  <div className="checkbox-and-radio-input-group">
                    <label>
                      <Field
                        id="policyAccepted"
                        name="policyAccepted"
                        type="checkbox"
                      />
                      <div className="checkmark"></div>
                      <span className="policy-span">
                        <b>{t("register_form.policy_1")}</b> {' '}
                        {t("register_form.policy_2")}
                      </span>
                    </label>
                    <ErrorMessage name="policyAccepted">
                      {(msg) => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <span>
                  {t("register_form.submit_pre_info1")} {' '}
                  <b>{t("register_form.submit_pre_info2")}</b>
                  {t("register_form.submit_pre_info3")}
                </span>

                <Field
                  name="submit"
                  className="contact-form__form__submit submit-btn"
                  type="submit"
                  value={t("contact.form.submit")}
                  disabled={submitSuccess === 'success'}
                />
              </Form>
            )}
          </Formik>

        </div>

      </div>
      <Modal
        isOpen={isSubmitModal}
        style={boxStyles}
        onRequestClose={() => setIsSubmitModal(false)}
        shouldCloseOnOverlayClick={true}>
        <div className="submit-register-form-modal-ctn">
          {
            submitSuccess === 'success' && (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none">
                  <circle cx="75" cy="75" r="70" stroke="#6EBDE9" strokeWidth="10" />
                  <path d="M45.2266 78.2978L65.4841 98.5554L105.999 58.0403" stroke="#4EBFEE" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <div>
                  <div>{t("register_form.submit_success_title")}</div>
                  <p>{t("register_form.submit_success_info")}</p>
                </div>
              </>
            )
          }
          {
            submitSuccess === 'error' && (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none">
                  <circle cx="75" cy="75" r="70" stroke="#FF0C0C" strokeWidth="10" />
                  <path d="M97 53L52 98" stroke="#FF0C0C" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M52 53L97 98" stroke="#FF0C0C" strokeWidth="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <div>
                  <div>{t("register_form.submit_error_title")}</div>
                  <p>{t("register_form.submit_error_info")}</p>
                </div>
              </>
            )
          }
        </div>
      </Modal>
    </section>
  );
}

const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_122_6159)">
      <path d="M12 9V13" stroke="#FAAA4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.3625 3.59095L2.2565 17.1249C2.08939 17.4143 2.00096 17.7424 2.00001 18.0766C1.99905 18.4108 2.0856 18.7393 2.25104 19.0297C2.41649 19.32 2.65506 19.562 2.94304 19.7315C3.23101 19.901 3.55835 19.9922 3.8925 19.9959H20.1065C20.4405 19.9921 20.7677 19.9009 21.0555 19.7314C21.3433 19.562 21.5818 19.3201 21.7472 19.0299C21.9126 18.7397 21.9992 18.4113 21.9984 18.0773C21.9976 17.7433 21.9094 17.4153 21.7425 17.1259L13.6365 3.58994C13.466 3.30845 13.2257 3.07569 12.9389 2.91414C12.6522 2.7526 12.3286 2.66772 11.9995 2.66772C11.6704 2.66772 11.3468 2.7526 11.06 2.91414C10.7733 3.07569 10.533 3.30845 10.3625 3.58994V3.59095Z" stroke="#FAAA4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 16H12.01" stroke="#FAAA4B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_122_6159">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const ExtraTeammate = ({ index, errors, touched, canRemove, remove }) => {
  const { t } = useTranslation();

  const nameFieldId = `teammateName${index + 1}`
  const emailFieldId = `teammateEmail${index + 1}`
  const phoneFieldId = `teammatePhone${index + 1}`

  let nameInputClassName = ""
  if (errors[nameFieldId] !== undefined && touched[nameFieldId] !== undefined) {
    nameInputClassName = "error";
  }

  let emailInputClassName = ""
  if (errors[emailFieldId] !== undefined && touched[emailFieldId] !== undefined) {
    emailInputClassName = "error";
  }

  let phoneInputClassName = ""
  if (errors[phoneFieldId] !== undefined && touched[phoneFieldId] !== undefined) {
    phoneInputClassName = "error";
  }

  return (
    <div className="teammate-ctn">
      <div className="header-ctn">
        {t("register_form.teammate") + ' #' + (index + 1)}
        {
          canRemove && (
            <a onClick={() => remove()}>
              {t("register_form.remove")}
            </a>
          )
        }
      </div>

      <div className="inputs-ctn">
        <label className="registration-form-item_wrapper">
          <span>{t("register_form.teammate_name")}</span>
          <Field
            id={nameFieldId}
            className={nameInputClassName}
            name={nameFieldId}
            type="text"
            placeholder={t("register_form.teammate_name_placeholder")}
          />
          <ErrorMessage name={nameFieldId}>
            {(msg) => <div className="error">{msg}</div>}
          </ErrorMessage>
        </label>

        <label className="registration-form-item_wrapper">
          <span>{t("register_form.teammate_email")}</span>
          <Field
            id={emailFieldId}
            className={emailInputClassName}
            name={emailFieldId}
            type="email"
            placeholder={t("register_form.teammate_email_placeholder")}
          />
          <ErrorMessage name={emailFieldId}>
            {(msg) => <div className="error">{msg}</div>}
          </ErrorMessage>
        </label>

        <label className="registration-form-item_wrapper">
          <span>{t("register_form.teammate_phone")}</span>
          <Field
            id={phoneFieldId}
            className={phoneInputClassName}
            name={phoneFieldId}
            type="tel"
            placeholder={t("register_form.phone_placeholder")}
          />
          <ErrorMessage name={phoneFieldId}>
            {(msg) => <div className="error">{msg}</div>}
          </ErrorMessage>
        </label>
      </div>

    </div>
  )
}

export default FormSection